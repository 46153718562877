@import "../../../assets/styles/variables";

.ErrorPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: $max-width;
  height: $max-height;
  padding: 20px;

  .title-custom {
    margin: 10px;
  }

  .iconbox, .textbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 10px;
  }

  .iconbox {

    .fa-ambulance {
      font-size: 48px;
    }
  }

  .textbox {
    .backToHome {
      padding: 5px 10px;
      border: 2px solid $title-bg;
      border-radius: 5px;
      background: $bg-color;
      color: #fff;
      transition: all .4s ease-in-out;

      &:hover {
        border: 2px solid $bg-color;
        background: $title-bg;
      }
    }
  }
}
