.ScrollToUpButton {
  position: fixed;
  bottom: 10px;
  right: -50px;
  opacity: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #0984e3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: none;
  transition: all 0.4s ease-in-out;
}
.ScrollToUpButton:hover {
  background: #94c3f8;
}
.ScrollToUpButton:hover .fas {
  color: #fff;
}
.ScrollToUpButton:active {
  bottom: 30px;
}
.ScrollToUpButton .fas {
  font-size: 21px;
  color: #4b6584;
}

