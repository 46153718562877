@import "../../../assets/styles/variables";

.Footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: $max-width;
  padding: 10px 0;
  font-size: $font;
  background: none;
  border-top: 1px solid $gray-border;

  .col {
    width: 30%;
    padding: 0 10px;
    margin: 0 10px;
    text-align: center;

    .text {
      margin: 5px 0;

      .author {
        cursor: default;
        color: #e67e22;
        font-weight: 600;
      }
    }

    .link, .navlink {
      outline: none;
    }

    .link {

      .fab {
        font-size: 24px;
        color: #000;
        -webkit-transition: color .4s ease-in-out;
        -o-transition: color .4s ease-in-out;
        transition: color .4s ease-in-out;
      }

      &:hover .fab {
        color: #4b7bec;
      }
    }
  }
}
