.ErrorPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.ErrorPage .title-custom {
  margin: 10px;
}
.ErrorPage .iconbox, .ErrorPage .textbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
}
.ErrorPage .iconbox .fa-ambulance {
  font-size: 48px;
}
.ErrorPage .textbox .backToHome {
  padding: 5px 10px;
  border: 2px solid #2d3436;
  border-radius: 5px;
  background: #4b6584;
  color: #fff;
  transition: all 0.4s ease-in-out;
}
.ErrorPage .textbox .backToHome:hover {
  border: 2px solid #4b6584;
  background: #2d3436;
}

