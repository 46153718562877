.Footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  background: none;
  border-top: 1px solid #747d8c;
}
.Footer .col {
  width: 30%;
  padding: 0 10px;
  margin: 0 10px;
  text-align: center;
}
.Footer .col .text {
  margin: 5px 0;
}
.Footer .col .text .author {
  cursor: default;
  color: #e67e22;
  font-weight: 600;
}
.Footer .col .link, .Footer .col .navlink {
  outline: none;
}
.Footer .col .link .fab {
  font-size: 24px;
  color: #000;
  -webkit-transition: color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
}
.Footer .col .link:hover .fab {
  color: #4b7bec;
}

