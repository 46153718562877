@import "../../../assets/styles/variables";

.ScrollToUpButton {
  position: fixed;
  bottom: 10px;
  right: -50px;
  opacity: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid $scroll-btn-border;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: none;
  transition: all .4s ease-in-out;

  &:hover {
    background: $scroll-btn-bg-hover;

    .fas {
      color: #fff;
    }
  }

  &:active {
    bottom: 30px;
  }

  .fas {
    font-size: 21px;
    color: $bg-color;
  }
}
