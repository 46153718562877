@import "../../../assets/styles/variables";

.VPO {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: $max-width;
  height: $max-height;

  .container-custom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .contact {
      width: 100%;
      text-align: center !important;

      .title {
        text-align: center;
        color: $title-color;
        background: $title-bg;
        padding: 10px 20px;
      }

      .infobox {
        margin: 10px 0;
        padding: 10px;

        .link {
          .image {
            width: 45%;
            object-fit: contain;
            border: 1px dashed #000;
            border-radius: 10px;
          }
        }

        h4 {
          .icon {
            width: 16px;
            height: 16px;
            position: relative;
            top: 1px;
          }
        }

        .fas {
          font-size: $icon-font;
        }

        .text {
          margin: 5px 0;
        }
      }
    }

    .content {
      width: 100%;

      .video-frame {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
          margin: 20px 0;
        }
      }

      &.custom-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .infobox {
          width: 90%;
          height: 630px;
          border-left: 1px solid #000;
          border-right: 1px solid #000;
        }
      }

      p.text {
        text-indent: 3em;
        text-align: justify;
      }

      .title {
        margin: 0;
        width: $max-width;
        text-align: center;
        color: $title-color;
        background: $title-bg;
        padding: 10px 20px;
      }

      .subtitle {
        text-align: center;
      }

      .textbox {
        padding: 10px 20px;

        .centered-box {
          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            width: 60%;
            height: 60%;

            max-height: 70vh;
            max-width: 45vw;
          }
        }

        .text {
          margin: 10px 0;
        }

        .items {
          &__item {
            margin: 5px 20px;
          }

          &.m-l-3em {
            margin-left: 3em;
          }
        }
      }
    }
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .VPO {
    .container-custom {
      .content {
        &.custom-content {
          .infobox {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .VPO {
    .container-custom {
      .content {
        &.custom-content {
          .infobox {
            height: 250px;
          }
        }
      }
    }
  }
}
