* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 24px;
}

html {
  scroll-behavior: smooth;
}

