.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  /*height: 100vh;*/
  height: 100%;
}

.pulseLoader {
  margin: 10px;
}
