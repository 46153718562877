// global
$max-width: 100%;                // max width
$max-height: 100%;               // max height
$font: 16px;                       // global font
$icon-font: 14px;                   // font for "font awesome" icons

$hover-color: #fcdd2c;          // hover menu color
$bg-color: #4b6584;             // menu background color
$block-color: #70a1ff;       // border and hover
$gray-border: #747d8c;
$link-color: #007bff;
$link-hover-color: #0056b3;

// sections
$title-bg: #2d3436;
$title-color: #fdcb6e;

// scrollbar
$scroll-track-bg: #f1f1f1;
$scroll-thumb-bg: #a59e9e;

// scroll-to-up button
$scroll-btn-border: #0984e3;
$scroll-btn-bg-hover: #94c3f8;

// tables
$table-title-color: #f00;


// @media (min-width: 769px) and (max-width: 1000px) {}
// @media (min-width: 571px) and (max-width: 768px) {}
// @media (min-width: 501px) and (max-width: 570px) {}
// @media (min-width: 320px) and (max-width: 500px) {}
