@import "../../../assets/styles/variables";

.Header {
  width: $max-width;
  background: $bg-color;
  color: #fff;

  .h-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: $max-width;

    .logo {
      width: 500px;
      height: 150px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .nav-controls {
      border: 1.5px solid #000;
      background: #fff;
      border-radius: 5px;
    }

    .navlink {
      font-family: "Comic Sans MS", sans-serif;
      color: #fff;
      padding: 5px 15px;
      outline: none;
      text-decoration: none;
      border: none;
      transition: all .35s ease-in-out;

      &:hover {
        color: $title-bg;
      }
    }

    .navlink-active {
      color: $hover-color !important;
    }

    .dropdown-toggle {
      padding: 0;
    }

    .dropdown-menu {
      color: #fff;
      background: $bg-color;
      border: 1px solid #fff;
      padding: 5px 0;

      .dropdown-item {
        padding: 5px 15px;

        &:hover {
          background: none;
        }
      }
    }
  }

// overriding react-bootstrap styles

  .dropdown {
    cursor: default;

    &.navlink-active .dropdown-toggle {
      color: $hover-color !important;
    }
  }

  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link.active {
    color: $hover-color;
  }
  .navbar-nav {
    position: relative;
    margin: auto;
  }
  .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .show>.nav-link {
    color: #fff;

    &:hover {
      color: $title-bg;
    }
  }
}

/* hide close-icon when burger is shown */
.navbar-toggler.collapsed .close-icon {
  display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}
/* **** */

@media (max-width: 1200px) {
  .navlink {
    width: 200px;
  }

  .navbar-nav {
    align-items: center;
  }

  //.dropdown {
  //  transform: translateY(-50%);
  //
  //  .dropdown-toggle {
  //    transform: translateY(50%);
  //  }
  //}
}

@media (min-width: 501px) and (max-width: 768px) {
  .logo {
    width: 400px !important;
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .logo {
    width: 270px !important;
  }
}
