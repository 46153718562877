.Header {
  width: 100%;
  background: #4b6584;
  color: #fff;
}
.Header .h-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.Header .h-container .logo {
  width: 500px;
  height: 150px;
  -o-object-fit: contain;
  object-fit: contain;
}
.Header .h-container .nav-controls {
  border: 1.5px solid #000;
  background: #fff;
  border-radius: 5px;
}
.Header .h-container .navlink {
  font-family: "Comic Sans MS", sans-serif;
  color: #fff;
  padding: 5px 15px;
  outline: none;
  text-decoration: none;
  border: none;
  transition: all 0.35s ease-in-out;
}
.Header .h-container .navlink:hover {
  color: #2d3436;
}
.Header .h-container .navlink-active {
  color: #fcdd2c !important;
}
.Header .h-container .dropdown-toggle {
  padding: 0;
}
.Header .h-container .dropdown-menu {
  color: #fff;
  background: #4b6584;
  border: 1px solid #fff;
  padding: 5px 0;
}
.Header .h-container .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}
.Header .h-container .dropdown-menu .dropdown-item:hover {
  background: none;
}
.Header .dropdown {
  cursor: default;
}
.Header .dropdown.navlink-active .dropdown-toggle {
  color: #fcdd2c !important;
}
.Header .navbar-light .navbar-nav .nav-link.active, .Header .navbar-light .navbar-nav .show > .nav-link.active {
  color: #fcdd2c;
}
.Header .navbar-nav {
  position: relative;
  margin: auto;
}
.Header .navbar-light .navbar-nav .nav-link, .Header .navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
}
.Header .navbar-light .navbar-nav .nav-link:hover, .Header .navbar-light .navbar-nav .show > .nav-link:hover {
  color: #2d3436;
}

/* hide close-icon when burger is shown */
.navbar-toggler.collapsed .close-icon {
  display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}

/* **** */
@media (max-width: 1200px) {
  .navlink {
    width: 200px;
  }
  .navbar-nav {
    align-items: center;
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .logo {
    width: 400px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .logo {
    width: 270px !important;
  }
}

