.VPO {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.VPO .container-custom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.VPO .container-custom .contact {
  width: 100%;
  text-align: center !important;
}
.VPO .container-custom .contact .title {
  text-align: center;
  color: #fdcb6e;
  background: #2d3436;
  padding: 10px 20px;
}
.VPO .container-custom .contact .infobox {
  margin: 10px 0;
  padding: 10px;
}
.VPO .container-custom .contact .infobox .link .image {
  width: 45%;
  object-fit: contain;
  border: 1px dashed #000;
  border-radius: 10px;
}
.VPO .container-custom .contact .infobox h4 .icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 1px;
}
.VPO .container-custom .contact .infobox .fas {
  font-size: 14px;
}
.VPO .container-custom .contact .infobox .text {
  margin: 5px 0;
}
.VPO .container-custom .content {
  width: 100%;
}
.VPO .container-custom .content .video-frame {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.VPO .container-custom .content .video-frame h4 {
  margin: 20px 0;
}
.VPO .container-custom .content.custom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.VPO .container-custom .content.custom-content .infobox {
  width: 90%;
  height: 630px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.VPO .container-custom .content p.text {
  text-indent: 3em;
  text-align: justify;
}
.VPO .container-custom .content .title {
  margin: 0;
  width: 100%;
  text-align: center;
  color: #fdcb6e;
  background: #2d3436;
  padding: 10px 20px;
}
.VPO .container-custom .content .subtitle {
  text-align: center;
}
.VPO .container-custom .content .textbox {
  padding: 10px 20px;
}
.VPO .container-custom .content .textbox .centered-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.VPO .container-custom .content .textbox .centered-box .image {
  width: 60%;
  height: 60%;
  max-height: 70vh;
  max-width: 45vw;
}
.VPO .container-custom .content .textbox .text {
  margin: 10px 0;
}
.VPO .container-custom .content .textbox .items__item {
  margin: 5px 20px;
}
.VPO .container-custom .content .textbox .items.m-l-3em {
  margin-left: 3em;
}

@media (min-width: 501px) and (max-width: 768px) {
  .VPO .container-custom .content.custom-content .infobox {
    height: 300px;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .VPO .container-custom .content.custom-content .infobox {
    height: 250px;
  }
}

